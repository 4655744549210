// extracted by mini-css-extract-plugin
export var talk = "TalkTemplate-module--talk--r8294";
export var title = "TalkTemplate-module--title--NVTnm";
export var meta = "TalkTemplate-module--meta--xY8+j";
export var iconEvent = "TalkTemplate-module--iconEvent--ZHa7X";
export var iconDate = "TalkTemplate-module--iconDate--Fe5vx";
export var sources = "TalkTemplate-module--sources--2sAtx";
export var source = "TalkTemplate-module--source--m+zrW";
export var disabled = "TalkTemplate-module--disabled--PmFNS";
export var tabSelector = "TalkTemplate-module--tabSelector--XpO7W";
export var hideTabs = "TalkTemplate-module--hideTabs--CK65N";
export var tab = "TalkTemplate-module--tab--RzP0C";
export var selected = "TalkTemplate-module--selected--j6wwl";
export var podcast = "TalkTemplate-module--podcast--MrXEo";
export var frameContainer = "TalkTemplate-module--frameContainer--bq3HC";
export var separator = "TalkTemplate-module--separator--FUFKj";
export var content = "TalkTemplate-module--content--AH+Sa";