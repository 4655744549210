import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { RiMapPin2Line, RiCalendarLine, RiBroadcastLine } from 'react-icons/ri';

import DateTime from 'src/components/DateTime';
import ExternalLink from 'src/components/ExternalLink';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import URLs from 'src/utils/urls';
import * as styles from './TalkTemplate.module.sass';


const TalkTemplate = ({ data, pageContext, location }) => {
  const talk = data.markdownRemark
  const { title, date, cover, event, source } = talk.frontmatter;
  // const { previous, next } = pageContext;

  return (
    <Layout location={location}>
      <SEO
        title={title}
        description={talk.excerpt}
        location={location}
        image={cover.publicURL}
      />

      <main>
        <article id="talk" className={styles.talk}>
          <header>
            <h1 className={styles.title}>
              {title}
            </h1>

            <div className={styles.meta}>
              <span>
                {event.isPodcast && (
                  <RiBroadcastLine className={styles.iconEvent} />
                ) || (
                  <RiMapPin2Line className={styles.iconEvent} />
                )}
                <span>
                  <ExternalLink to={event.url}>{event.name}</ExternalLink>
                  {event.city && `, ${event.city}`}
                </span>
              </span>

              <span>
                <RiCalendarLine className={styles.iconDate} />
                <DateTime date={date.published} format="long" />
              </span>
            </div>
          </header>

          <TalkSource
            title={title}
            cover={cover}
            isPodcast={event.isPodcast || false}
            source={source}
          />

          <section
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: talk.html }}
          />

          <hr className={styles.separator} />
        </article>
      </main>
    </Layout>
  )
};

const DISPLAY_ORDER = ['youtube', 'speakerdeck'];
const LABELS = {youtube: 'Video', speakerdeck: 'Slides'};

const TalkSource = ({ title, cover, isPodcast, source }) => {
  if (isPodcast) {
    return (
      <section className={styles.sources}>
        <div className={styles.podcast}>
          <GatsbyImage image={cover.childImageSharp.gatsbyImageData} alt={title} />
          <audio controls>
            <source src={source.audio.file.publicURL} type="audio/mpeg" />
            <span>Your browser does not support audio playback</span>
          </audio>
        </div>
      </section>
    );
  }

  const available = DISPLAY_ORDER.filter(key => !!source[key]);
  const [selectedSource, setSource] = useState(available[0]);
  const tabsClass = available.length <= 1 ? styles.hideTabs : '';

  return (
    <section className={styles.sources}>
      {available.map(key => (
        <div key={key} className={`
          ${styles.source}
          ${key === selectedSource ? '' : styles.disabled}
        `}>
          {key === 'youtube' && (
            <YoutubeEmbed
              id={source.youtube.id}
              title={title}
            />
          )}

          {key === 'speakerdeck' && (
            <SpeakerDeckEmbed
              source={source.speakerdeck}
              title={title}
            />
          )}
        </div>
      ))}

      <ul className={`${styles.tabSelector} ${tabsClass}`}>
        {available.map(key =>
          <li
            key={key}
            className={`
              ${styles.tab}
              ${key === selectedSource ? styles.selected : ''}
            `}
            onClick={() => setSource(key)}
          >
            {LABELS[key]}
          </li>
        )}
      </ul>
    </section>
  );
}

const YoutubeEmbed = ({ id, title }) => {
  return (
    <div className={styles.frameContainer}>
      <iframe
        src={URLs.youtubeEmbed(id)}
        title={`Talk video for "${title}"`}
        scrolling="no"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allowfullscreen
      >
      </iframe>
    </div>
  );
};

const SpeakerDeckEmbed = ({ source, title }) => {
  const ref = useRef(null);
  useEffect(() => ref.current.focus(), []);

  return (
    <div className={styles.frameContainer}>
      <iframe
        ref={ref}
        src={URLs.speakerdeckEmbed(source.id)}
        title={`Talk slides for "${title}"`}
        scrolling="no"
        allow="encrypted-media"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allowFullScreen
      >
      </iframe>
    </div>
  );
};



export const pageQuery = graphql`
  query TalkBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, type: { eq: "talks" } }) {
      id
      html
      excerpt
      frontmatter {
        title
        date { published }
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1.77777778
              width: 1000
              quality: 100
            )
          }
        }
        event { name, url, city, isPodcast }
        source {
          speakerdeck { id, download_url }
          youtube { id }
          audio { file { publicURL } }
        }
      }
    }
  }
`;


export default TalkTemplate;
